import * as React from "react";
import '@spectrum-web-components/themes';
import '@spectrum-web-components/link';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';

import { Logo } from './Logo';
import { Description } from './Description';
import { Ahureko } from './Ahureko';
import { PopularAhurekos } from './PopularAhurekos';
import { CorkRnD } from './CorkRnD';
import { Footer } from './Footer';
import PageView from './PageView';

interface AppState {
}

export class App extends React.Component<{}, AppState> {
    render() {
        return (
            <Router>
              <sp-theme color="light">
                <div className="Header">
                  <Link to="/">
                    <Logo />
                  </Link>
                  <h1>マンガでアフレコ</h1>
                </div>
                <Switch>
                  <Route path="/ahureko">
                    <PageView />
                    <Ahureko />
                  </Route>
                  <Route path="/">
                    <PageView />
                    <Description />
                    <section className="manga-list">
                      <div className="manga">
                        <div className="manga-title">目的地に着く前が旅行です。 #しんぱいいぬ</div>
                        <div className="manga-thumbnail"><Link to="/ahureko?anno=/shinpai-inu/shinpai-inu-03.jsonld"><img src="/images/shinpai-inu-03-thumb.jpeg" alt="目的地に着く前が旅行です。 #しんぱいいぬ" /></Link></div>
                        <div className="manga-link"><Link to="/ahureko?anno=/shinpai-inu/shinpai-inu-03.jsonld">セリフを録音</Link></div>
                      </div>
                      <div className="manga">
                        <div className="manga-title">コロナも花粉も来てるのにマスクがない。一時的にはこれでしのぐのもアリかも。 #しんぱいいぬ</div>
                        <div className="manga-thumbnail"><Link to="/ahureko?anno=/shinpai-inu/shinpai-inu-02.jsonld"><img src="/images/shinpai-inu-02-thumb.jpeg" alt="コロナも花粉も来てるのにマスクがない。一時的にはこれでしのぐのもアリかも。 #しんぱいいぬ" /></Link></div>
                        <div className="manga-link"><Link to="/ahureko?anno=/shinpai-inu/shinpai-inu-02.jsonld">セリフを録音</Link></div>
                      </div>
                      <div className="manga">
                        <div className="manga-title">昨日体に悪いモノ食べちゃった人は、今日のお昼は健康なもの食べてリセットしよう！ #しんぱいいぬ</div>
                        <div className="manga-thumbnail"><Link to="/ahureko?anno=/shinpai-inu/shinpai-inu-01.jsonld"><img src="/images/shinpai-inu-01-thumb.jpeg" alt="昨日体に悪いモノ食べちゃった人は、今日のお昼は健康なもの食べてリセットしよう！ #しんぱいいぬ" /></Link></div>
                        <div className="manga-link"><Link to="/ahureko?anno=/shinpai-inu/shinpai-inu-01.jsonld">セリフを録音</Link></div>
                      </div>
                    </section>
                    <PopularAhurekos />
                    <CorkRnD/>
                    <Footer />
                  </Route>
                </Switch>
              </sp-theme>
            </Router>
        );
    }
}
