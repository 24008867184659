import * as React from 'react';
import { Link } from 'react-router-dom';

interface SavedAreaProps {
    shareURL: URL,
    creator: any,
}

interface SavedAreaState {

}

export class SavedArea extends React.Component<SavedAreaProps, SavedAreaState> {
    render() {
        let { shareURL, creator } = this.props;
        let shareText = '';
        if (creator.name) {
            shareText += `${creator.name} さんのアフレコ\n`;
        }
        if (creator.message) {
            shareText += `「${creator.message}」\n`;
        }
        shareText += 'マンガでアフレコ\n';
        shareText = encodeURIComponent(shareText);
        let shareURLText = encodeURIComponent(shareURL.toString());

        return (
            <div className="SavedArea">
              <ul className="shares">
                <li><a href={`https://twitter.com/share?url=${shareURLText}&text=${shareText}`} target="twitter" rel="noopener noreferrer"><img src="/images/twitter.svg" alt="Twitterでシェア" /></a></li>
                <li><a href={`https://www.facebook.com/share.php?u=${shareURLText}`} target="facebook" rel="noopener noreferrer"><img src="/images/facebook.svg" alt="Facebookでシェア" /></a></li>
                <li><a href={`https://social-plugins.line.me/lineit/share?url=${shareText}${shareURLText}`} target="line" rel="noopener noreferrer"><img src="/images/line.svg" alt="LINEでシェア" /></a></li>
              </ul>
              <div className="share-url"><input type="url" value={shareURLText} /></div>
              <ul className="warnings">
                <li>※表示されているURLで、このアフレコを再生できます。</li>
              </ul>
              <div className="link-to-top"><Link to="/">別のマンガでアフレコする</Link></div>
              <ul className="warnings">
                <li>※この画面を離れると、このアフレコの編集ができなくなります。</li>
              </ul>
            </div>
        );
    }
}
