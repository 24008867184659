import * as React from "react";

export class Logo extends React.Component<{}, {}> {
    render() {
        return (
            <div className="Logo">
              <img src="/images/logo.png" alt="○×実験室" />
            </div>
        );
    }
}
