import * as React from 'react';
import Modal from 'react-modal';

interface HowToUseProps {
    
}

interface HowToUseState {
    modalIsOpen: boolean;
}

export class HowToUse extends React.Component<HowToUseProps, HowToUseState> {
    constructor(props: HowToUseProps) {
        super(props);
        this.state = {
            modalIsOpen: false,
        };
    }

    render() {
        let { modalIsOpen } = this.state;
        let openModal = this.openModal.bind(this);
        let closeModal = this.closeModal.bind(this);

        return (
            <div className="HowToUse">
              <button onClick={openModal}>使い方</button>
              <Modal
                isOpen={modalIsOpen}
                shouldCloseOnOverlayClick={true}
                onRequestClose={closeModal}
              >
                <ul>
                  <li><img src="/images/start-record-icon-enabled.svg" alt="録音ボタン" />を押して録音を開始！</li>
                  <li>順番に拡大されるコマのポップアップに合わせて、セリフをアフレコしよう！
                    <br />※画面下に表示される録音時間に気をつけてね！</li>
                  <li>アフレコが完成したら「お名前」と「一言メッセージ」を入力して、「アフレコを保存」ボタンを押してみよう！<br />
                    ※「おすすめアフレコ」に掲載可能 チェックボックスにチェックを入れると、トップページのおすすめアフレコに掲載されるかも！？</li>
                  <li>アフレコが終わっても<img src="/images/start-record-icon-enabled.svg" alt="録音ボタン" />で撮り直しできます！何度でも楽しんでみてね！</li>
                  {/* <li>アフレコし終わっても、コマごとに録音し直せる！</li> */}
                </ul>
              </Modal>
            </div>
        );
    }

    openModal() {
        this.setState({
            modalIsOpen: true,
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
        });
    }
}
