import * as React from 'react';

import { Logo } from './Logo';

export class Footer extends React.Component<{}, {}> {
    render() {
        return (
            <footer className="Footer">
              <Logo />
              <ul>
                <li><sp-link href="https://note.corkagency.com/m/m021903987155">○×実験室note</sp-link></li>
                <li><sp-link href="https://corkagency.com/privacypolicy">プライバシーポリシー</sp-link></li>
              </ul>
            </footer>
        );
    }
}
