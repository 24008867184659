import * as React from 'react';
import 'fabric';
declare let fabric: any;
import {MediaFragment, MediaFragmentSpatial} from '@kitaitimakoto/media-fragment';

interface ImageProps {
    anno: AnnotationCollection,
    index?: number,
    handlePanelSelected: (index?: number) => void,
}

interface ImageState {
    canvas?: any,
    panels: any[],
    img?: any,
}

const multiplier: number = 2;

export class Image extends React.Component<ImageProps, ImageState> {
    constructor(props: ImageProps) {
        super(props);
        this.state = {
            panels: [],
        };
    }

    componentDidMount() {
        this.initCanvas(this.props.anno);
    }

    render() {
        this.state.panels.forEach((panel, index) => {
            if (index === this.props.index) {
                this.highlightPanel(panel.img);
            } else {
                this.dehighlightPanel(panel.img);
            }
        });

        return (
            <div ref="container" className="Image">
              <canvas ref="canvas" />
            </div>
        );
    }

    initCanvas(anno: AnnotationCollection) {
        let items = anno.first.items;

        let sources = items.reduce((srcs: string[], item: Annotation) => {
            if (! srcs.includes(item.target.source)) {
                srcs.push(item.target.source);
            }
            return srcs;
        }, []);
        if (sources.length > 1) {
            throw new Error("Only single image file is allowed for a single <Ahureko> component");
        }
        let imageSrc = sources[0];

        fabric.Image.fromURL(imageSrc, (img: any) => {
            let canvas = new fabric.Canvas(this.refs.canvas, {
                selection : false,
                controlsAboveOverlay:true,
                centeredScaling:true,
                allowTouchScrolling: true
            });
            canvas.hoverCursor = 'auto';
            canvas.on('touch:drag', (e: any) => {
                console.debug(e);
            });
            this.setState({ canvas, img });
            this.resizeCanvas();
            let items = this.props.anno.first.items;
            canvas.setBackgroundImage(img, () => canvas.renderAll());
            for (let i in items) {
                let index = Number.parseInt(i);
                this.initPanel(items[i], index);
            }
        });
    }

    initPanel(anno: Annotation, index: number) {
        let {canvas, panels, img} = this.state;
        let mf = new MediaFragment(anno.target.selector.value);
        let coord = new MediaFragmentSpatial(mf.get('xywh'));
        let res = coord.resolve(canvas.width, canvas.height);
        let options = {
            format: 'jpeg',
            top: res.y,
            left: res.x,
            width: res.w,
            height: res.h,
            multiplier
        };
        img.cloneAsImage((img: any) => {
            img.selectable = false;
            img.set({
                top: options.top,
                left: options.left
            });
            let center: any = img.getCenterPoint();
            img.set({
                originX: 'center',
                originY: 'center',
                top: center.y - options.height / multiplier,
                left: center.x - options.width / multiplier
            });
            img.scale(1 / multiplier);
            // img.on('mouseup', (event: any) => {
            //     for (let i in panels) {
            //         let panel = panels[i].img;
            //         if (Number.parseInt(i) === index) {
            //             if (panel.shadow && panel.shadow.blur > 0) {
            //                 this.props.handlePanelSelected(undefined);
            //             } else {
            //                 this.props.handlePanelSelected(index);
            //             }
            //         }
            //     }
            // });
            canvas.add(img);
            panels.push({img});
        }, options);
    }

    highlightPanel(panel: any) {
        if (panel.shadow && panel.shadow.offsetX > 0) return;

        let canvas = this.state.canvas;
        panel.setShadow({blur: 2, offsetX: 2, offsetY: 2, color: 'rgb(126, 126, 126)'});
        panel.bringToFront();
        canvas.renderAll();
        panel.animate({scaleX: 1.2 / multiplier, scaleY: 1.2 / multiplier}, {
            duration: 100,
            onChange: () => canvas.renderAll()
        });
    }

    dehighlightPanel(panel: any) {
        if (panel.shadow && panel.shadow.offsetX === 0) return;

        panel.set({
            scaleX: 1 / multiplier,
            scaleY: 1 / multiplier,
            shadow: {
                blur: 0,
                offsetX: 0,
                offsetY: 0
            }
        });
        this.state.canvas.renderAll();
    }

    resizeCanvas() {
        let { canvas, img } = this.state;
        if (! img) return;
        if (! canvas) return;
        let container: any = this.refs.container; // FIXME
        let width = Math.min(container.clientWidth, img.width);
        let height = (width / img.width) * img.height;
        canvas.setDimensions({width, height});
        img.scaleToWidth(width);
    }
}
