import * as React from 'react';

interface SeekBarProps {
    duration?: number,
    currentTime: number,
}

interface SeekBarState {

}

export class SeekBar extends React.Component<SeekBarProps, SeekBarState> {
    render() {
        let { duration, currentTime } = this.props;
        let ratio = (duration === undefined) ? 0 : (currentTime / duration as number) * 100;

        return (
            <div className="SeekBar">
              <div className="bar">
                <div className="current-time" style={ {width: `${ratio}%`} }></div>
              </div>
              <div className="numbers">
                <span>{this.formatTime(currentTime)}</span>/<span>{this.formatTime(duration)}</span>
              </div>
            </div>
        );
    }

    formatTime(timeInSeconds?: number) {
        if (timeInSeconds === undefined) return '-';

        let sec = Math.floor(timeInSeconds);
        let prec = (timeInSeconds - sec).toString().slice(2, 4);
        if (prec.length === 0) prec = '00';
        if (prec.length === 1) prec = prec + '0';
        return `${sec}:${prec}`;
    }
}
