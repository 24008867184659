import * as React from 'react';

interface RecordProps {
    disabled: boolean,
    handleClick: () => void; // Change to stopRecord function;
}

export class Record extends React.Component<RecordProps, {}> {
    render() {
        let { disabled, handleClick } = this.props;
        // When disabled is false, index should be not undefined
        let icon = disabled ? "/images/recording-icon-disabled.svg" : "/images/start-record-icon-enabled.svg";

        return (
            <div className="Record">
              <button disabled={disabled} onClick={(_: any) => handleClick()}><img src={icon} alt="録音" /></button>
            </div>
        );
    }
}
