import * as React from 'react';
import '@spectrum-web-components/icon';

interface PlayProps {
    index?: number,
    disabled: boolean,
    audioSrcs: string[],
    abortSignal?: AbortSignal,
    handleClicked: () => void,
    handleAudioUpdated: (duration?: number, currentTime?: number) => void,
    handleAudioEnded: () => void,
}

interface PlayState {
    intervalID?: any,
}

export class Play extends React.Component<PlayProps, PlayState> {
    render() {
        let { index, disabled, audioSrcs, abortSignal, handleClicked, handleAudioUpdated, handleAudioEnded } = this.props;
        let intervalID = this.state?.intervalID;
        let audios = audioSrcs.map((src: string, i: number) =>
                                   <audio src={src} preload="auto" key={i}
                                          controls={false}
                                          ref={`audio-${i}`}></audio>);

        audios.forEach((_: any, i: number) => {
            let elem: any = this.refs[`audio-${i}`];
            if (! elem) return;
            if (i === index) {
                abortSignal?.addEventListener('abort', () => {
                    handleAudioUpdated(undefined, undefined);
                    elem.pause();
                });
                elem.play();
            } else {
                elem.pause();
            }
        });

        if (index === undefined) {
            if (intervalID !== undefined) {
                clearInterval(intervalID);
                this.setState({
                    intervalID: undefined,
                });
            }
        } else {
            if (intervalID === undefined) {
                let interval = 100;
                let intervalID = setInterval(() => {
                    let audio: any = this.getCurrentAudio();
                    handleAudioUpdated(audio.duration, audio.currentTime);
                }, interval);
                this.setState({
                    intervalID,
                });
            }
        }

        let icon = disabled ? "/images/play-icon-disabled.svg" : "/images/play-icon-enabled.svg";

        return (
            <div className="Play">
              <button onClick={handleClicked} disabled={disabled}><img src={icon} alt="再生" /></button>
              {audios}
            </div>
        );
    }

    getCurrentAudio(): any {
        let { index } = this.props;
        return this.refs[`audio-${index}`];
    }
}
