import * as React from "react";

export class Description extends React.Component<{}, {}> {
    render() {
        return (
            <p className="Description">
              いろんなマンガに声をアフレコして<br />
              友達や家族におくってみよう！<br />
              おすすめアフレコを参考に、<br />
              一人で録音したり、みんなでワイワイ楽しくアフレコしよう！
            </p>
        );
    }
}
