import * as React from 'react';

interface PanelProgressProps {
    totalPanels: number,
    index?: number,
}

interface PanelProgressState {

}

export class PanelProgress extends React.Component<PanelProgressProps, PanelProgressState> {
    render() {
        let { totalPanels, index } = this.props;
        let currentPanel = index === undefined ? '-' : (index + 1).toString();

        return (
            <div className="PanelProgress">
              <span className="current-panel">{currentPanel}</span>/<span className="total-panels">{totalPanels}</span>
            </div>
        );
    }
}
