import * as React from 'react';

export class CorkRnD extends React.Component<{}, {}> {
    render() {
        return (
            <div className="CorkRnD">
              <div className="CorkRnD-inner">
                <h2>○×実験室 </h2>
                <p>IT/テクノロジーとマンガや小説で、色々とできることがあるのではないか。
                  そんな思いから、立ち上げた「コルクの○×実験室」プロジェクト。
                  毎回、色々なテーマで試行錯誤していこうと思いますので、お楽しみに！ </p>
                <sp-link href="https://note.corkagency.com/n/n46cdfa864d29" class="link-to-details">詳しくはこちらから</sp-link>
              </div>
            </div>
        );
    }
}
