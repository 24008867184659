import * as React from 'react';

interface StopProps {
    disabled: boolean,
    handleClick: () => void,
}

export class Stop extends React.Component<StopProps, {}> {
    render() {
        let { disabled, handleClick } = this.props;
        let icon = disabled ? "/images/stop-icon-disabled.svg" : "/images/stop-icon-enabled.svg";

        return (
            <div className="Stop">
              <button disabled={disabled} onClick={handleClick}><img src={icon} alt="停止" /></button>
            </div>
        );
    }
}
