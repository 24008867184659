import * as React from 'react';

interface CountDownProps {
    count: number,
}

interface CountDownState {

}

export class CountDown extends React.Component<CountDownProps, CountDownState> {
    render() {
        let { count } = this.props;

        return (
            <div className="CountDown"><img src={`/images/count_${count}_icon.svg`} alt={`残り${count}秒`} /></div>
        );
    }
}
