import * as React from "react";
import { useLocation } from 'react-router-dom';
import MediaRecorder from 'audio-recorder-polyfill';
import durationToSenconds from 'duration-to-seconds';

import { Image } from './Image';
import { Record } from './Record';
import { Play } from './Play';
import { Stop } from './Stop';
import { PanelProgress } from './PanelProgress';
import { SeekBar } from './SeekBar';
import { SaveArea } from './SaveArea';
import { SavedArea } from './SavedArea';
import { HowToUse } from './HowToUse';
import { CountDown } from './CountDown';

interface AhurekoProps {

}

interface AhurekoState {
    anno: AnnotationCollection,
    panelIndex?: number,
    playing: boolean,
    recording: boolean,
    recorder?: any,
    audioChunks: Blob[],
    recordStoreId: string | null,
    shareURL: URL,
    recordStoreIdExistsAtInit: boolean,
    audioSrcs: string[],
    panelRecordingState: string, // ready, recording, aborting, completed
    abortController?: AbortController,
    streams: MediaStream[],
    audioDuration?: number,
    audioCurrentTime?: number,
    creator?: any,
    countDown?: number,
}

export class Ahureko extends React.Component<AhurekoProps, AhurekoState> {
    constructor(props: AhurekoProps) {
        super(props);
        let location = new URL(window.location.href);
        let annoURL = location.searchParams.get('anno');
        if (! annoURL) {
            annoURL = '/shinpai-inu/shinpai-inu-03.jsonld';
        }
        let recordStoreId = location.searchParams.get('recordStoreId');
        this.state = {
            anno: {
                first: { items: [] },
                label: '',
                id: '',
            },
            playing: false,
            recording: false,
            recordStoreId,
            shareURL: location,
            recordStoreIdExistsAtInit: !!recordStoreId,
            audioSrcs: [],
            panelRecordingState: 'ready',
            audioChunks: [],
            streams: [],
        };
        fetch(annoURL)
            .then((res: any) => res.json())
            .then((anno: AnnotationCollection) => this.setState({anno}))
            .catch((error: Error) => console.error(error));
        if (recordStoreId) {
            window.firebase.firestore().collection('ahurekos').doc(recordStoreId).get()
                .then((doc: any) => {
                    if (doc.exists) {
                        let data = doc.data();
                        let audioSrcs: string[] = [];
                        if (data.audio) {
                            for (let i in data.audio) {
                                audioSrcs[Number.parseInt(i)] = data.audio[i];
                            }
                        }
                        this.setState({
                            recordStoreId,
                            shareURL: location,
                            audioSrcs,
                            creator: data.creator,
                        });
                    } else {
                        throw Error('document not found');
                    }
                });
        } else {
            window.firebase.firestore().collection('ahurekos').add({})
                .then((docRef: any) => {
                    let recordStoreId = docRef.id;
                    location.searchParams.append('recordStoreId', recordStoreId);
                    window.history.replaceState({}, '', location.toString());
                    this.setState({
                        recordStoreId,
                        shareURL: location,
                    });
                })
                .catch((err: any) => console.error(err));
        }
    }

    render() {
        let { anno, recordStoreId, panelIndex, playing, recording, audioSrcs, recordStoreIdExistsAtInit, abortController, audioDuration, audioCurrentTime, shareURL, creator, countDown } = this.state;

        let stopped = (! playing) && (! recording);

        let playable;
        let recordable;
        let stoppable;
        if (recordStoreIdExistsAtInit) {
            recordable = false; // <Record> is not rendered
            if (panelIndex === undefined) {
                if (playing) {
                    throw new Error('Should not reach here');
                } else if (recording) {
                    throw new Error('Should not reach here');
                } else {
                    playable = true;
                    stoppable = false;
                }
            } else {
                if (playing) {
                    playable = false;
                    stoppable = true;
                } else if (recording) {
                    throw new Error('Should not reach here');
                } else {
                    playable = true;
                    stoppable = false;
                }
            }
        } else {
            if (panelIndex === undefined) {
                // コマ選択してない
                if (audioSrcs.length > 0) {
                    // 一個以上録音してる
                    if (playing) {
                        playable = false;
                        recordable = false;
                        stoppable = true;
                    } else if (recording) {
                        throw new Error('Should not reach here');
                    } else {
                        playable = true;
                        recordable = true;
                        stoppable = false;
                    }
                } else {
                    // 一個も録音していない
                    if (playing) {
                        throw new Error('Should not reach here');
                    } else if (recording) {
                        throw new Error('Should not reach here');
                    } else {
                        playable = false;
                        recordable = true;
                        stoppable = false;
                    }
                }
            } else {
                // コマを一つ選択している
                if (audioSrcs.length > 0) {
                    // 一個以上録音してる
                    if (playing) {
                        playable = false;
                        recordable = false;
                        stoppable = true;
                    } else if (recording) {
                        playable = false;
                        recordable = false;
                        stoppable = true;
                    } else {
                        playable = false;
                        recordable = false;
                        stoppable = false;
                    }
                } else {
                    // 一個も録音してない
                    if (playing) {
                        playable = false;
                        recordable = false;
                        stoppable = true;
                    } else if (recording) {
                        playable = false;
                        recordable = false;
                        stoppable = true;
                    } else {
                        playable = false;
                        recordable = false;
                        stoppable = false;
                    }
                }
            }
        }

        if (panelIndex === undefined) {
            audioDuration = undefined;
            audioCurrentTime = 0;
        } else if (audioDuration === undefined) {
            let dur = anno.first.items[panelIndex as number].body['schema:Duration'];
            audioDuration = durationToSenconds(dur);
            audioCurrentTime = audioCurrentTime || 0;
        }

        let rendersHowToUse = ! recordStoreIdExistsAtInit;
        let rendersSaveArea = (! recordStoreIdExistsAtInit) && (audioSrcs.length === anno.first.items.length);
        let rendersSavedArea = (! recordStoreIdExistsAtInit) && (creator !== undefined) && (!! creator);

        return (
            <div className="Ahureko">
              <h2>{anno.label}</h2>
              {
                  creator ?
                      <ul className="ahureko-creator">
                        <li>{creator.image ? <span className="creator-image"><img src={creator.image} alt="" /></span> : ''}{creator.name} さんの録音</li>
                        <li>「{creator.message}」</li>
                      </ul> :
                  ''
              }
              {anno.first.items.length > 0 ? <Image anno={anno} index={panelIndex} handlePanelSelected={this.handlePanelSelected.bind(this)} /> : ""}
              <p className="copyright">©<a href="https://yajima-syoukai.com/" target="yajimakenji_site">やじまけんじ</a> <a href="https://twitter.com/yajima_kenji" target="yajimakenji_twitter"><img src="/images/twitter.svg" alt="" />@yajima_kenji</a></p>
              <ul className="warnings">
                <li>※本サービス「マンガでアフレコ」利用者間に生じたトラブルについて、<br />運営会社である株式会社コルクは一切の責任を負わないものとします。<br />録音した音声を共有する際には、共有相手を充分ご確認ください。</li>
              </ul>
              <div className="controls">
                {rendersHowToUse ? <HowToUse /> : ''}
                <div className="buttons">
                  <Play
                    index={playing ? panelIndex : undefined}
                    disabled={! playable}
                    audioSrcs={ audioSrcs }
                    abortSignal={abortController?.signal}
                    handleClicked={this.handlePlayStarted.bind(this)}
                    handleAudioUpdated={this.handleAudioUpdated.bind(this)}
                    handleAudioEnded={this.handleAudioEnded.bind(this)}/>
                  {recordStoreIdExistsAtInit ? "" : <Record disabled={! recordable} handleClick={this.handleRecordStarted.bind(this)} />}
                  <Stop disabled={! stoppable} handleClick={() => this.handleStopped()} />
                </div>
                <PanelProgress totalPanels={anno.first.items.length} index={panelIndex} />
                <SeekBar duration={audioDuration as number} currentTime={audioCurrentTime as number} />
              </div>
              {rendersSaveArea ? <SaveArea recordStoreId={recordStoreId as string} handleSubmitted={this.handleSubmitted.bind(this)} saved={!! creator} /> : ''}
              {rendersSavedArea ? <SavedArea shareURL={shareURL} creator={creator} /> : '' }
              {countDown ? <CountDown count={countDown} /> : ''}
            </div>
        );
    }

    handlePanelSelected(panelIndex?: number) {
        if (this.state.recordStoreIdExistsAtInit) return;
        this.setState({panelIndex});
    }

    handleStopped() {
        if (this.state.playing) {
            this.finishPlaying();
        } else if (this.state.recording) {
            this.abortRecording();
        }
        this.setState({
            playing: false,
            recording: false,
        });
    }

    handleRecordStarted() {
        let recorderOptions = {type: 'audio/wave'};
        let workerOptions = {
            OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
            WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
        };
        let items = this.state.anno.first.items;
        let abortController = new AbortController();
        let signal = abortController.signal;
        signal.addEventListener('abort', () => this.abortRecording());
        let countDown = [3, 2, 1].reduce((p: Promise<any>, count: number) => {
            return p.then(() => {
                return new Promise((resolve, reject) => {
                    this.setState({countDown: count});
                    let timeoutID = setTimeout(() => resolve(), 1000);
                    signal.addEventListener('abort', () => {
                        clearTimeout(timeoutID);
                        this.setState({countDown: undefined});
                        reject();
                    });
                });
            });
        }, Promise.resolve());
        countDown = countDown.then(() => this.setState({countDown: undefined}));
        let promise = items.reduce((p: Promise<any>, item: Annotation, index: number) => {
            return p.then(() => {
                return navigator.mediaDevices.getUserMedia({audio: true})
                    .then((stream: MediaStream) => {
                        return new Promise((resolve, reject) => {
                            let recorder = new MediaRecorder(stream, recorderOptions, workerOptions);
                            let chunks: Blob[] = [];
                            let streams = this.state.streams;
                            streams.push(stream);
                            this.setState({
                                recorder,
                                audioChunks: chunks,
                                streams,
                            });
                            recorder.addEventListener('dataavailable', (event: any) => {
                                chunks.push(event.data);
                                this.setState({audioChunks: chunks});
                            });
                            let dur = durationToSenconds(item.body['schema:Duration']);
                            let timeoutID = setTimeout(() => {
                                if (index === items.length - 1) {
                                    this.finishRecording();
                                } else {
                                    this.transitRecordingPanel();
                                }
                                resolve();
                                clearInterval(intervalID);// TODO: hoistingが分かりづらい
                                this.setState({audioCurrentTime: undefined});
                            }, dur * 1000);
                            let interval = 100;
                            let intervalID = setInterval(() => {
                                let currentTime = this.state.audioCurrentTime === undefined ? 0 : this.state.audioCurrentTime as number;
                                let newTime = currentTime + interval * 0.001;
                                this.setState({audioCurrentTime: newTime});
                            }, interval);
                            signal.addEventListener('abort', () => {
                                clearTimeout(timeoutID);
                                clearInterval(intervalID);
                                this.setState({audioCurrentTime: undefined});
                                reject();
                            });
                            recorder.start();
                        });
                });
            });
        }, countDown);
        promise.catch((err: Error) => console.error(err));
        this.setState({
            panelIndex: 0,
            recording: true,
            panelRecordingState: 'recording',
            abortController,
        });
    }

    handleAudioURLCreated(audioURL: string): string[] {
        let audioSrcs = this.state.audioSrcs;
        audioSrcs[this.state.panelIndex as number] = audioURL;
        return audioSrcs;
    }

    saveAudio(blob: Blob) {
        let { panelIndex, recordStoreId } = this.state;
        window.firebase.storage().ref().child(`${recordStoreId}/voice-${panelIndex}.wav`)
            .put(blob, {contentType: 'audio/wave'})
            .then((snapshot: any) => {
                return snapshot.ref.getDownloadURL()
                    .then((url: string) => {
                        return window.firebase.firestore().collection('ahurekos')
                            .doc(recordStoreId)
                            .update({[`audio.${panelIndex}`]: url});
                    });
            })
            .catch((err: object) => console.error(err));
    }

    transitRecordingPanel() {
        this.setState({
            panelRecordingState: 'completed',
        });
        let { panelIndex, recorder, audioChunks } = this.state;
        recorder.addEventListener('stop', () => {
            let blob = new Blob(audioChunks, {type: 'audio/wave'});
            this.saveAudio(blob);
            let audioSrcs = this.handleAudioURLCreated(URL.createObjectURL(blob));
            this.setState({
                panelRecordingState: 'recording',
                panelIndex: (panelIndex || 0) + 1,
                audioSrcs,
            });
        });
        recorder.stop();
    }

    abortRecording() {
        this.setState({
            panelIndex: undefined,
            panelRecordingState: 'aborting',
        });
        let { recorder, audioChunks, abortController } = this.state;
        abortController?.abort();
        recorder?.stop();
        this.stopTracks();
        this.setState({
            recording: false,
            panelRecordingState: 'ready',
            streams: [],
        });
    }

    finishRecording() {
        this.setState({
            panelRecordingState: 'completed',
            recording: false,
        });
        let { panelIndex, recorder, audioChunks } = this.state;
        recorder.addEventListener('stop', () => {
            let blob = new Blob(audioChunks, {type: 'audio/wave'});
            this.saveAudio(blob);
            let audioSrcs = this.handleAudioURLCreated(URL.createObjectURL(blob));
            this.stopTracks();
            this.setState({
                panelRecordingState: 'ready',
                panelIndex: undefined,
                audioSrcs,
                streams: [],
            });
        });
        recorder.stop();
        setTimeout(() => {
            let input = window.document.getElementById('creator-name')
            if (! input) return;
            input.scrollIntoView();
            setTimeout(() => input?.focus(), 300);
        }, 300);
    }

    stopTracks() {
        for (let stream of this.state.streams) {
            for (let track of stream.getAudioTracks()) {
                track.stop();
            }
        }
    }

    handlePlayStarted() {
        let { audioSrcs } = this.state;

        this.setState({
            playing: true,
            panelIndex: 0,
            abortController: new AbortController(),
        });
    }

    handleAudioEnded() {
        let { panelIndex, audioSrcs } = this.state;

        if (panelIndex === audioSrcs.length - 1) {
            this.finishPlaying();
        } else {
            this.transitPlayingPanel();
        }
    }

    transitPlayingPanel() {
        let { panelIndex, playing } = this.state;

        let next = playing ? (panelIndex || 0) + 1 : undefined;
        this.setState({
            panelIndex: next,
        });
    }

    finishPlaying() {
        this.setState({
            playing: false,
            panelIndex: undefined,
            abortController: undefined,
            audioDuration: undefined,
            audioCurrentTime: undefined,
        });
    }

    handleSubmitted(event: any) {
        event.preventDefault();
        let { recordStoreId } = this.state;
        let data = new FormData(event.target);
        let imageFile: any = data.get('image');
        let imageSaved = Promise.resolve('');
        if (imageFile && imageFile.size !== 0) {
            imageFile = imageFile as File;
            let type = imageFile.type;
            let ext = this.determineImageExtension(type as string);
            imageSaved = imageSaved.then(() => {
                return window.firebase.storage().ref().child(`${recordStoreId}/creator-image${ext}`)
                    .put(imageFile, {contentType: type})
                    .then((snapshot: any) =>  snapshot.ref.getDownloadURL());
            });
        }
        imageSaved.then((imageURL: string) => {
            let creator: any = {
                name: data.get('name'),
                message: data.get('message'),
                okToList: data.has('ok-to-list'),
            };
            if (imageURL !== '') {
                creator.image = imageURL;
            }
            return window.firebase.firestore().collection('ahurekos')
                .doc(recordStoreId)
                .update({
                    creator,
                    uri: window.location.href,
                    createdAt: new Date(Date.now()),
                })
                .then(() => {
                    this.setState({creator});
                })
        }).catch((err: Error) => console.error(err));;
    }

    determineImageExtension(type: string) {
        switch(type) {
        case 'image/svg+xml':
            return '.svg';
            break;
        case 'image/jpeg':
            return '.jpeg';
            break;
        case 'image/png':
            return '.png';
            break;
        case 'image/gif':
            return '.png';
            break;
        case 'image/tiff':
            return '.tiff';
            break;
        case 'image/webp':
            return '.webp';
            break;
        }

        return '';
    }

    handleAudioUpdated(duration?: number, currentTime?: number) {
        if ((duration !== undefined) &&
            (currentTime !== undefined) &&
            ((currentTime as number) >= (duration as number))) {
            this.handleAudioEnded();
            return;
        }

        this.setState({
            audioDuration: duration,
            audioCurrentTime: currentTime,
        });
    }
}
