import * as React from 'react';
import { Link } from 'react-router-dom';

export class PopularAhurekos extends React.Component<{}, {}> {
    render() {
        return (
            <div className="PopularAhurekos">
              <h2>おすすめアフレコ</h2>
              <ul>
                <li>
                  <Link to="/ahureko?anno=%2Fshinpai-inu%2Fshinpai-inu-03.jsonld&recordStoreId=mwjWSKNV2PdxcCXyYWPe">
                    <span className="popular-ahureko-title"><img src="/images/shinpai-inu-03-icon.jpeg" alt="" />目的地に着く前が旅行です。 #しんぱいいぬ</span>
                    <span className="popular-ahureko-link">あいことまっぴー さんのアフレコ</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ahureko?anno=%2Fshinpai-inu%2Fshinpai-inu-02.jsonld&recordStoreId=uBbrxlwSs2da9JIdscok">
                    <span className="popular-ahureko-title"><img src="/images/shinpai-inu-02-icon.jpeg" alt="" />コロナも花粉も来てるのにマスクがない。一時的にはこれでしのぐのもアリかも。 #しんぱいいぬ</span>
                    <span className="popular-ahureko-link">ウエチン さんのアフレコ</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ahureko?anno=%2Fshinpai-inu%2Fshinpai-inu-01.jsonld&recordStoreId=ZlfcUQULoRc6rpZ3rHlR">
                    <span className="popular-ahureko-title"><img src="/images/shinpai-inu-01-icon.jpeg" alt="" />昨日体に悪いモノ食べちゃった人は、今日のお昼は健康なもの食べてリセットしよう！ #しんぱいいぬ</span>
                    <span className="popular-ahureko-link">やじまりも さんのアフレコ</span>
                  </Link>
                </li>
              </ul>
            </div>
        );
    }
}
