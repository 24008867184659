import * as React from 'react';
import { withRouter } from 'react-router';

interface PageViewInnerProps {
    match: any,
    location: any,
    history: any,
}

class PageViewInner extends React.Component<PageViewInnerProps, {}> {
    render() {
        window.firebase.analytics().logEvent('page_view');
        return '';
    }
}

export default withRouter(PageViewInner);
