import * as React from 'react';

interface SaveAreaProps {
    recordStoreId: string,
    handleSubmitted: (event: any) => void,
    saved: boolean;
}

interface SaveAreaState {
    imageURL?: string,
}

export class SaveArea extends React.Component<SaveAreaProps, SaveAreaState> {
    render() {
        let { recordStoreId, handleSubmitted, saved } = this.props;

        return (
            <div className="SaveArea">
              <form onSubmit={handleSubmitted}>
                <input name="recordStoreId" type="hidden" value={recordStoreId} />
                <div className="identity">
                  <div className="creator-image-fields">
                    <label htmlFor="creator-image"><img src={this.state?.imageURL || "/images/icon_upload_icon.svg"} alt="アイコン画像をアップロード" /></label>
                    <input name="image" type="file" accept="image/*" id="creator-image" onChange={this.updateImage.bind(this)} disabled={saved} />
                  </div>
                  <div className="creator-name-fields">
                    <label htmlFor="creator-name">お名前</label>
                    <input name="name" type="text" id="creator-name" placeholder="お名前の入力" readOnly={saved} />
                  </div>
                </div>
                <label htmlFor="creator-message">一言メッセージ</label>
                <input type="text" name="message" id="creator-message" placeholder="メッセージの入力" readOnly={saved} />
                <label id="creator-ok-to-list"><input name="ok-to-list" type="checkbox" disabled={saved} />「おすすめアフレコ」に掲載可能</label>
                {saved ? '' : <input type="submit" value="アフレコを保存" />}
              </form>
              <ul className="warnings">
                <li>※保存後に表示されるURLで、このアフレコを再生できます。</li>
                <li>※この画面を離れると、このアフレコの編集ができなくなります。</li>
              </ul>
            </div>
        );
    }

    updateImage(event: any) {
        let file = event.target.files[0];
        if (! file) return;
        let fileURL = URL.createObjectURL(file);
        this.setState({
            imageURL: fileURL
        });
    }
}
